<template>
    <nav id="navbar" class="navbar" role="navigation" aria-label="Main navigation">
        <div class="navigation-container">
            <h1>
                <router-link :to="{ name: 'home' }" replace>
                    Joseph Budiarto
                </router-link>
            </h1>
            <div id="burger" class="burger" @click="burgerOnClick">
                <span class="line1"/>
                <span class="line2"/>
                <span class="line3"/>
            </div>
            <div id="navbar-items" class="navbar-items">
                <div class="navbar-item" v-for="item in navigation" :key="item">
                    <router-link :to="{ name: item }" replace @click.native="closeOverlay">
                        {{ item }}
                    </router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Navigation',
    data: () => {
        return {
            navigation: [
                'experience',
                'education',
                'skills',
                'others',
                'contact',
            ],
        };
    },
    methods: {
        burgerOnClick () {
            let burger = document.getElementById('burger');
            let navbarItems = document.getElementById('navbar-items');

            burger.classList.toggle('is-active');
            navbarItems.classList.toggle('is-active');

            if (burger.classList.contains('is-active')) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        },
        closeOverlay () {
            let burger = document.getElementById('burger');
            let navbarItems = document.getElementById('navbar-items');

            if (burger.classList.contains('is-active')) {
                burger.classList.remove('is-active');
                document.body.style.overflow = 'auto';
            }

            if (navbarItems.classList.contains('is-active')) {
                navbarItems.classList.remove('is-active');
            }
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../sass/navbar';
a {
    text-decoration: none;
    color: unset;
}
</style>
