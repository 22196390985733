import '@/sass/app.scss';
import Vue from 'vue';
import App from './App.vue';
import router from './router';

import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import toastini from 'v-tostini';

(async () => {
  Vue.config.productionTip = process.env.NODE_ENV === 'production';
  Vue.config.silent = false;
  Vue.config.devtools = process.env.NODE_ENV !== 'production';

  library.add(
      faFacebookF,
      faInstagram,
      faLinkedinIn,
      faCog,
  );
  dom.watch();
  Vue.use(toastini);

  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app');
})();

