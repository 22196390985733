<template>
    <div id="main">

        <navigation/>

        <transition name="fade">
            <router-view/>
        </transition>

        <!-- Footer -->
<!--        <section class="footer">-->
<!--            <div class="container">-->
<!--                &copy; 2021 Joseph Budiarto-->
<!--            </div>-->
<!--        </section>-->

        <!-- Toast -->
        <tostini-plate class="tostini-plate"
                       v-slot="{ type, message, close }">
            <div @click="close">
                <span>{{ message }}</span>
                <a class="close"
                   data-dismiss="tostini"
                   aria-label="close" @click="close">&times;</a>
            </div>
        </tostini-plate>
    </div>
</template>

<script>
import Navigation from '@/components/Navigation';
export default {
    name: 'App',
    components: {
        Navigation,
    },
    data: () => {
        return {
            captchaIsLoaded: false,
        };
    },
    mounted () {
        window.addEventListener('DOMContentLoaded', () => {
            this.getScript();
        });
    },
    beforeDestroy() {},
    methods: {
        getScript() {
            if (this.captchaIsLoaded) {
                return;
            }
            this.captchaIsLoaded = true;
            setTimeout(() => {
                const element = document.createElement("script");
                element.src = "https://www.google.com/recaptcha/api.js?render=6LdPpy4bAAAAAK6LBkPcEFEHcoLZqgYwXQrfNMV9";
                element.setAttribute('defer', 'true');
                element.setAttribute('async', 'true');
                document.body.appendChild(element);
            }, 3000);
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
