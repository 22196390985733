import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(
            /* webpackChunkName: "home" */
            '../views/Home.vue'
        )
    },
    {
        path: '/experience',
        name: 'experience',
        component: () => import(
            /* webpackChunkName: "home" */
            '../views/Home.vue'
        )
    },
    {
        path: '/education',
        name: 'education',
        component: () => import(
            /* webpackChunkName: "home" */
            '../views/Home.vue'
        )
    },
    {
        path: '/skills',
        name: 'skills',
        component: () => import(
            /* webpackChunkName: "home" */
            '../views/Home.vue'
        )
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import(
            /* webpackChunkName: "home" */
            '../views/Home.vue'
        )
    },
    {
        path: '/others',
        name: 'others',
        component: () => import(
            /* webpackChunkName: "others"*/
            '../views/Others.vue'
        )
    }
];

const router = new VueRouter({
    routes,
    mode: 'history',

    // eslint-disable-next-line no-unused-vars
    scrollBehavior (to, from, savedPosition) {
        if (to.name) {
            const el = document.getElementById(to.name);
            const x = el === null ? 0 : el.offsetLeft;
            const y = el === null ? 0 : (el.offsetTop - 50);

            return {x, y, behavior: 'smooth'};
        }
    },
});

router.beforeEach(async (to, from, next) => {
    if (to.name === null) {
        next({ name: 'home' });
    } else {
        next();
    }
});

export default router;
